// Global
body {
	background-color: #F5F5F5;
	font-family: 'Montserrat', sans-serif;
}

header {
	background-color: #282828;
	padding: 3rem 1.5rem !important;
	color: #00A482;

	&.hero {
		background-image: url(/images/Pattern.png);
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: 70%;
		height: 80vh;

		h1 {
			color: white;
		}
	}

	@media (min-width: 992px) {
		background-size: 972px;
	}

	.caption {
		margin-top: 120px;
		font-weight: 600;
		line-height: 22px;
		font-size: 18px;
		position: relative;
		padding-left: 60px;
		padding-right: 60px;

		&:after {
			content: '';
			background-color: #00A482;
			width: 100px;
			height: 14px;
			position: absolute;
			bottom: -30px;
    		left: 76px;
		}
	}
}

footer {
	padding: 3rem 85px !important;

	.footer-links {
		padding-top: 1.5rem;
		@media (min-width: 992px) {
			text-align: right;
		}
	
		a {
			margin: 0 .5rem;
			color: #282828;
			font-family: Montserrat;
			font-size: 11px;
			letter-spacing: 0;
			line-height: 14px;
			font-weight: 400;
		}
	}
}