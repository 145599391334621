// Type
h1 {
	font-family: 'Montserrat', sans-serif;
	font-weight: 700;
	font-size: 60px;
	line-height: 60px;
	margin-bottom: 30px;
}

a {
	font-weight: 600;
	color: #282828;

	&:hover {
		text-decoration: underline;
		color: #282828;
	}
}